<template>
  <MDialog v-model="modalShow" :title="title" @onOk="submitForm" top="40px" :width="width- 320 + 'px'"
           @fullScreen="fullScreenChange" :showFullScreen="true">
    <div class="m-table">
      <FunctionHeader v-model="functionHeader.searchVal" search-title="请输入物料编号/名称/型号" class="materials-head"
                      :tags="functionHeader.tags" :columns-setting="false" @search="getReportItemLog()"
                      :isDialog=true>

        <el-select v-model="processId" clearable placeholder="选择工序" @change="selectProcess"
                   style="margin-left:10px">
          <el-option
            v-for="item in processList"
            :key="item.procedureId"
            :label="item.procedureFullName"
            :value="item.procedureId"
          />
        </el-select>

        <el-select v-model="userIds" multiple collapse-tags @change="getReportItemLog" placeholder="请选择用户组" style="margin-left: 20px">
          <el-option
            v-for="item in userGroupOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>

        <!--<el-select v-model="moduleId" clearable placeholder="选择模组" @change="selectModule" style="margin-left:10px;" multiple>-->
        <!--<el-option-->
        <!--v-for="item in moduleList"-->
        <!--:key="item.materialsId"-->
        <!--:label="item.materialsFullName"-->
        <!--:value="item.materialsId"-->
        <!--/>-->
        <!--</el-select>-->
      </FunctionHeader>
      <el-table ref="table" :data="dataProcess" :height="tableHeight" @expand-change="onExpand" v-loading="loading"
                :default-expand-all="isExpand">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.children" :ref="'expandTable' + props.row.procedureLocationCode"
                      :header-cell-class-name="DisableSelection2(props.row.children)"
                      :height="props.row.children.length >5 ? 300 : 'auto'"
                      row-key="id"
                      class="ellipsis-table" @selection-change="(data) => handleSelectionChange(data, props.$index)">
              <el-table-column type="selection" :reserve-selection="true" :selectable="selectable">
              </el-table-column>
              <el-table-column prop="index" label="序号" width="60">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="materialsType" label="物料类型">
                <template slot-scope="scope">
                  {{ scope.row.materialsType }}
                </template>
              </el-table-column>
              <el-table-column prop="materialsCode" label="物料编号"></el-table-column>
              <el-table-column prop="materialsName" label="物料名称" width="200"></el-table-column>
              <el-table-column prop="specifications" label="物料规格"></el-table-column>

              <el-table-column prop="isKeyMaterials" label="是否关键物料">
                <template slot-scope="scope">
                  {{ scope.row.isKeyMaterials ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="mainUnitName" label="主单位"></el-table-column>
              <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
              <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>

              <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
              <el-table-column prop="appliedUnissuedQuantity" label="申请未发数量"></el-table-column>
              <el-table-column prop="stockQuantity" label="库存数"></el-table-column>
              <el-table-column prop="allocatedStockQuantity" label="分配数"></el-table-column>
              <el-table-column prop="requireDate" label="需求日期"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="procedureName" label="工序名称"></el-table-column>
        <el-table-column prop="procedureLocationCode" label="工序定位码"></el-table-column>
        <el-table-column prop="applyRate" label="申请率">
          <template slot-scope="scope">
            {{ scope.row.applyRate}}%
          </template>
        </el-table-column>
        <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
        <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
        <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
        <el-table-column prop="allocatedStockQuantity" label="分配数"></el-table-column>
        <el-table-column prop="supplyQuantity" label="供应数"></el-table-column>
        <el-table-column prop="sitCompleteRate" label="线边齐套率">
          <template slot-scope="scope"> {{ scope.row.sitCompleteRate }}%</template>
        </el-table-column>
        <el-table-column prop="completeRate" label="齐套率">
          <template slot-scope="scope">
            <span :class="getCellClass(scope.row.completeRate)">{{ scope.row.completeRate}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="keyMaterialsRequirementQuantity" label="关键料数量"></el-table-column>
        <el-table-column prop="keyMaterialsSitCompleteRate" label="关键料线边齐套率">
          <template slot-scope="scope"> {{ scope.row.keyMaterialsSitCompleteRate }}%</template>
        </el-table-column>
        <el-table-column prop="keyMaterialsCompleteRate" label="关键料齐套率">
          <template slot-scope="scope">{{ scope.row.keyMaterialsCompleteRate }}%</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-container">
      <el-pagination
        :current-page="page.currentPage"
        :page-sizes="page.pageSizes"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </MDialog>
</template>

<script>
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import Split from '@/components/Split/Split'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader'
import mapi from '@/api/information/materials-require'
import apiUser from "@/api/sets/user/user";
import baseSettingApi from '@/api/sets/base-setting'

export default {
  name: 'SelectMaterialsInventory',
  components: {MaterialsClass, Split, SearchForm, FunctionHeader},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectProcessMaterials: {
      type: Array,
      default: () => []
    },
    receiptId: {
      type: Number,
      default: undefined
    },
    processFlowId: {
      type: Number,
      default: undefined
    },
    processFlowVersion: {
      type: Number,
      default: undefined
    },
    receiptType: {
      type: Number,
      default: undefined
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userGroupOptions: [],
      userIds:'',
      modalShow: false,
      dataProcess: [],
      functionHeader: {
        searchVal: '',
        tags: []
      },
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 30, 50, 100, 150, 200],
        total: 0,
        currentPage: 1
      },
      selectionData: [],
      originProcessSelectionDatas: [],
      fullScreen: false,
      loading: false,
      isClassTableExpanded: false,
      moduleList: [],
      processList: [],
      processId: '',
      moduleId: '',
      isChooseProcess: false,
      isChooseModule: false,
      isExpand: false,
      missMaterialsColorRatio: 0
    }
  },
  computed: {
    width() {
      return this.$store.state.app.contentWidth
    },
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    tableHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 - 44 - 52 : this.$store.state.app.contentHeight - 289 - 45 - 44 - 52
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      this.processId = ''
      this.moduleId = []
      this.isExpand = false
      if (val) {
        this.getReportItemLog()
        this.getProcessList()
        this.getModuleList()
      }
    },
    modalShow(val) {
      if (!val) {
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    }
  },
  async mounted() {
    this.modalShow = this.visible
    await this.getMissMaterialsColorRatio()
    await this.getUserGroupList()
    if (this.modalShow) {
      await this.getReportItemLog()
      await this.getProcessList()
      await this.getModuleList()
    }
  },
  methods: {
    DisableSelection2(data){
      const fina = data.filter(item => !item.selectable)
      if(fina.length === 0){
        return "DisableSelection"
      }else {
        return ""
      }
    },
    async getUserGroupList() {
      const res = await apiUser.getUserGroupList()
      if (res) {
        this.userGroupOptions = res.map(item => ({
          id:item.id,
          name:item.name
        }))
      }
    },
    // 获取工序物料表
    async getReportItemLog() {
      this.loading = true
      let processCondition = {}
      let moduleCondition = {}
      if (this.isChooseProcess) {
        processCondition = {
          procedureId: this.processId,
          procedureLocationCode: this.procedureLocationCode
        }
      }
      if (this.isChooseModule) {
        moduleCondition = {
          moduleMaterialsIds: this.moduleId.join(',')
        }
      }
      const res = await mapi.getProcedureMaterialsListPage({
        userGroupIds:this.userIds ? this.userIds.join(',') : '',
        page: this.page.currentPage,
        size: this.page.pageSize,
        search: this.functionHeader.searchVal,
        receiptId: this.receiptId,
        receiptType: this.receiptType,
        ...processCondition,
        ...moduleCondition
      })
      if (res) {
        this.loading = false
        this.dataProcess = res.records.map((item) => {
          return {
            ...item,
            children: item.children.map((items) => {
              return {
                ...items,
                selectable: items.notReceiveQuantity - items.appliedUnissuedQuantity > 0 ? false : true
              }
            })
          }
        })
        this.page.total = res.total
      }
      this.loading = false
    },
    // 获取工序下拉列表
    async getProcessList() {
      const res = await mapi.getProcessList({receiptId: this.receiptId})
      if (res) {
        this.processList = res
      }
    },
    // 获取模组下拉列表
    async getModuleList() {
      let processCondition = {}
      if (this.isChooseProcess) {
        processCondition = {
          procedureId: this.processId,
          procedureLocationCode: this.procedureLocationCode
        }
      }
      const res = await mapi.getModuleList({
        processFlowId: this.processFlowId,
        processFlowVersionId: this.processFlowVersion, ...processCondition
      })
      if (res) {
        this.moduleList = res
      }
    },
    // 选择工序
    async selectProcess(val) {
      this.isChooseProcess = val
      this.moduleList = []
      this.moduleId = []
      this.getModuleList()
      await this.getReportItemLog()
      if (val || this.isChooseModule) {
        this.isExpand = true
      } else {
        this.isExpand = false
      }
      this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
    },
    // 递归展开收起
    toggleRowExpansionForAll(data, isExpansion) {
      data.forEach(item => {
        this.$refs.table.toggleRowExpansion(item, isExpansion)
        if (item.children) {
          this.toggleRowExpansionForAll(item.children, isExpansion)
        }
      })
    },
    // 选择模组
    async selectModule(val) {
      this.isChooseModule = val.length
      await this.getReportItemLog()
      if (this.isChooseModule || this.isChooseProcess) {
        this.isExpand = true
      } else {
        this.isExpand = false
      }
      this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
      if (this.isChooseModule) {
        this.dataProcess.forEach(item => {
          let expandTable = "expandTable" + item.procedureLocationCode
          item.children.forEach(items => {
            this.$nextTick(() => {
              if (!items.selectable) {
                this.$refs[expandTable].toggleRowSelection(items, true)
              }
            })
          })
        })
      } else {
        this.selectionData = []
      }
    },
    onExpand(row, rows) {
      let _this = this
      _this.getExpandMaterialsData(row, rows)
    },
    // 获取工序展开物料
    async getExpandMaterialsData(row, rows) {
      let _this = this
      let procedureLocationCode = row.procedureLocationCode
      let expandTable = "expandTable" + procedureLocationCode
      // table展开时，根据之前选中的选项通过toggleRowSelection点击checkbox
      _this.$nextTick(function () {
        if (_this.$refs[expandTable]) {
          let hasSelections =
            _this.selectProcessMaterials.length > 0
              ? _this.selectProcessMaterials.length
              : undefined > 0
          if (hasSelections) {
            _this.isClassTableExpanded = true
            let selectedIds = _this.selectProcessMaterials.map(
              (mSelect) => mSelect.id
            )
            if (row.children) {
              row.children.forEach((row) => {
                if (selectedIds.includes(row.id)) {
                  _this.$refs[expandTable].toggleRowSelection(row, true)
                }
              })
            }
          }
        }
        _this.isClassTableExpanded = false
      })
    },
    handleSelectionChange(index, data) {
      this.originProcessSelectionDatas[data] = index
      this.selectionData = this.originProcessSelectionDatas.flat()
    },
    selectable(row) {
      if (row.selectable) {
        return false
      }
      return true
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getReportItemLog()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getReportItemLog()
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    submitForm(callback) {
      const uniqueArr = this.selectionData.reduce((unique, item) => {
        if (!unique.some(obj => JSON.stringify(obj) === JSON.stringify(item))) {
          unique.push(item)
        }
        return unique
      }, [])
      this.$emit('submitForm', uniqueArr)
    },

    // 获取缺料颜色提示比例
    async getMissMaterialsColorRatio() {
      const res = await baseSettingApi.getCodeNoCheck({code: 'miss_materials_color_ratio'})
      if (res) {
        this.missMaterialsColorRatio = res.value
      }
    },

    // 根据数值计算颜色
    getCellClass(value) {
      const colorRatio = !this.missMaterialsColorRatio ? 0 : this.missMaterialsColorRatio;
      const gradient = Math.floor(value / 10); // 计算梯度
      if (value >= colorRatio) {
        return `color-green-${gradient}`;
      } else {
        return `color-red-${gradient}`;// 返回对应的 CSS 类
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table .DisableSelection .cell .el-checkbox__inner{
  background-color: var(--el-checkbox-disabled-input-fill);
  cursor: not-allowed;
}

.materials-info {
  padding: 10px;
}

.materials-head {
  padding: 0 0 10px 0;
}

::v-deep {
  .el-select__tags-text {
    display: inline-block;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-tag__close.el-icon-close {
    top: -7px;
  }
}

.page-container {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.m-table {
  position: relative;

  ::v-deep {
    .el-table .cell {
      padding-left: 10px !important;
    }
  }
  .color-green-0 {
    background: #ebffeb;
    color: white
  }
  .color-green-1 {
    background: #d2f9d2;
    color: white
  }
  .color-green-2 {
    background: #baf3ba;
    color: white
  }
  .color-green-3 {
    background: #a4eda4;
    color: white
  }
  .color-green-4 {
    background: #8fe78f;
    color: white
  }
  .color-green-5 {
    background: #6bda6b;
    color: white
  }
  .color-green-6 {
    background: #51ce51;
    color: white
  }
  .color-green-7 {
    background: #3dc23d;
    color: white
  }
  .color-green-8 {
    background: #2fb62f;
    color: white
  }
  .color-green-9 {
    background: #25aa25;
    color: white
  }
  .color-green-10 {
    background: #1f9e1f;
    color: white
  }

  .color-red-0 {
    background: #9e1f1f;
    color: white
  }
  .color-red-1 {
    background: #aa2525;
    color: white
  }
  .color-red-2 {
    background: #b62f2f;
    color: white
  }
  .color-red-3 {
    background: #c23d3d;
    color: white
  }
  .color-red-4 {
    background: #ce5151;
    color: white }
  .color-red-5 {
    background: #da6c6c;
    color: white
  }
  .color-red-6 {
    background: #e79090;
    color: white
  }
  .color-red-7 {
    background: #eda6a6;
    color: white
  }
  .color-red-8 {
    background: #f3bcbc;
    color: white
  }
  .color-red-9 {
    background: #f9d4d4;
    color: white
  }
  .color-red-10 {
    background: #ffeded;
    color: white
  }

}
</style>
