var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: {
            "form-list-extend": _vm.searchFormList,
            "form-data": _vm.searchFormData,
            "default-form": _vm.defaultForm,
            searchType: 1
          },
          on: { search: _vm.searchTable }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("FunctionHeader", {
          ref: "functionHeader",
          attrs: {
            "search-title": _vm.mode
              ? "请输入单据名称/编号/订单名称/订单编号"
              : "请输入单据名称/编号/项目名称/项目编号",
            tags: _vm.functionHeader.tags,
            "export-name": _vm.functionHeader.exportName,
            "columns-key": _vm.columnsKey,
            "export-params": _vm.functionHeader.exportParams
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.getColumns,
            height: _vm.height,
            "columns-key": _vm.columnsKey,
            "set-data-method": _vm.getTableData,
            onlyKey: "receiptId"
          },
          on: { "selection-change": _vm.selectionChange },
          scopedSlots: _vm._u(
            [
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "receiptNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: _vm.getDetailPath(row, "view"),
                      text: row.receiptNumber
                    }
                  })
                }
              },
              {
                key: "areaIdList",
                fn: function(ref) {
                  var row = ref.row
                  return row.areaList
                    ? _c(
                        "div",
                        {},
                        _vm._l(row.areaList, function(item) {
                          return _c(
                            "el-tag",
                            {
                              key: item.id,
                              staticClass: "role-tags",
                              attrs: { color: "#F6F7F9" }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                }
              },
              {
                key: "receiptType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm._f("receiptType")(row.receiptType)))
                  ])
                }
              },
              {
                key: "receiptStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm._f("receiptStatus")(row.receiptStatus)))
                  ])
                }
              },
              {
                key: "workOrderCompleteRate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.workOrderCompleteRate) + "%")
                  ])
                }
              },
              {
                key: "keyMaterialsCompleteRate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.keyMaterialsCompleteRate) + "%")
                  ])
                }
              },
              {
                key: "applyRate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [_vm._v(_vm._s(row.applyRate) + "%")])
                }
              },
              {
                key: "isEnabled",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
                  ])
                }
              },
              {
                key: "isPartialOutbound",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background: row.isPartialOutbound
                          ? "#24CAD7"
                          : "#FFCC78"
                      }
                    }),
                    _c("div", [
                      _vm._v(_vm._s(row.isPartialOutbound ? "启用" : "禁用"))
                    ])
                  ])
                }
              },
              {
                key: "type",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.procedureClass[row.type].name))
                    ])
                  ])
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }